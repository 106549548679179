
.bg-debug-red
{
    background-color: rgba(255, 0, 0, 0.05);
}
.bg-debug-green
{
    background-color: rgba(0, 255, 0, 0.05);
}

.debugImg
{
    position: absolute;
    max-width: 100vw;
    max-height: 100vh;
    opacity: 0.2;
}
.debugPanel
{
    opacity: 0.5;
}


@font-face
{
    font-family: 'Roboto-Black';
    src: url('../../assets/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Black.woff') format('woff'),
         url('../../assets/fonts/Roboto-Black.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Black.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Black.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-BlackItalic';
    src: url('../../assets/fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-BlackItalic.woff') format('woff'),
         url('../../assets/fonts/Roboto-BlackItalic.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-BlackItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-BlackItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-Bold';
    src: url('../../assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Bold.woff') format('woff'),
         url('../../assets/fonts/Roboto-Bold.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Bold.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Bold.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-BoldItalic';
    src: url('../../assets/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-BoldItalic.woff') format('woff'),
         url('../../assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-BoldItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-BoldItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-Italic';
    src: url('../../assets/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Italic.woff') format('woff'),
         url('../../assets/fonts/Roboto-Italic.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Italic.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Italic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-Light';
    src: url('../../assets/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Light.woff') format('woff'),
         url('../../assets/fonts/Roboto-Light.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Light.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Light.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-LightItalic';
    src: url('../../assets/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-LightItalic.woff') format('woff'),
         url('../../assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-LightItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-LightItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-Medium';
    src: url('../../assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Medium.woff') format('woff'),
         url('../../assets/fonts/Roboto-Medium.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Medium.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Medium.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-MediumItalic';
    src: url('../../assets/fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-MediumItalic.woff') format('woff'),
         url('../../assets/fonts/Roboto-MediumItalic.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-MediumItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-MediumItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-Regular';
    src: url('../../assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Regular.woff') format('woff'),
         url('../../assets/fonts/Roboto-Regular.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Regular.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Regular.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-Thin';
    src: url('../../assets/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-Thin.woff') format('woff'),
         url('../../assets/fonts/Roboto-Thin.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-Thin.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-Thin.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Roboto-ThinItalic';
    src: url('../../assets/fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Roboto-ThinItalic.woff') format('woff'),
         url('../../assets/fonts/Roboto-ThinItalic.woff2') format('woff2'),
         url('../../assets/fonts/Roboto-ThinItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Roboto-ThinItalic.svg#svgFontName') format('svg');
}

.typo-header
{
    font-family: 'Roboto-Bold';
    font-size: 1.7rem;
    line-height: 1.7rem;
    color: white;
}
.typo-chapter-title
{
    text-transform: uppercase;
    font-family: 'Roboto';
    font-size: 2.2rem;
    line-height: 2.2rem;
    color: white;
}
.typo-header-chapter
{
    font-family: 'Roboto';
    font-size: 2.75rem;
    line-height: 2.75rem;
    color: $color-1;
}
.typo-chapter-content
{
    font-family: 'Roboto-Medium';
    font-size: 1rem;
    color: $color-1;
}
.typo-chapter-number
{
    font-family: 'Roboto-Bold';
    font-size: 4.8rem;
    line-height: 4.8rem;
    color: rgba($color: #fff, $alpha: 0.5);
}
.typo-subchapter-number
{
    font-family: 'Roboto-Bold';
    font-size: 3.6rem;
    line-height: 3.6rem;
    color: $color-1;
}
.typo-title
{
    font-family: 'Roboto-Regular';
    font-size: 1.75em;
    line-height: 1.75rem;
    color: white;
}
.typo-template-title
{
    font-family: 'Roboto-Bold';
    font-size: 2.75em;
    line-height: 2.75rem;
    color: $color-1;
}
.typo-template-subtitle
{
    font-family: 'Roboto-Bold';
    font-size: 1rem;
    line-height: 1rem;
    color: $color-1;
}
.typo-description
{
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    line-height: 1.2rem;
    color: $color-19;
}
.typo-modal-header
{
    font-family: 'Roboto-Regular';
    font-size: 1.25rem;
    line-height: 1.25rem;
    color:  $color-1;
}
.typo-error
{
    text-transform: uppercase;
    font-family: 'Roboto-MediumItalic';
    font-size: .8rem;
    color: red;
}

@media (max-width: 1200px)
{
    .typo-chapter-title
    {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    
    .typo-header-chapter
    {
        font-family: 'Roboto';
        font-size: 2.25rem;
        line-height: 2.25rem;
        color: $color-1;
    }
    .typo-title
    {
        font-size: 1.5em;
        line-height: 1.5rem;
    }
    .typo-description
    {
        font-size: .85rem;
        line-height: 1.1rem;
    }
}

@media (max-width: 768px)
{
    .typo-chapter-title
    {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    
    .typo-header-chapter
    {
        font-family: 'Roboto';
        font-size: 2.25rem;
        line-height: 2.25rem;
        color: $color-1;
    }
}

@media (max-width: 576px)
{
    .typo-header
    {
        font-size: .8rem;
        line-height: 0rem;
    }
    .typo-chapter-title
    {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    .typo-template-title
    {
        font-size: 1.75em;
        line-height: 1.75rem;
    }
    .typo-header-chapter
    {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    .typo-title
    {
        font-size: 1.25em;
        line-height: 1.25rem;
    }
    .typo-description
    {
        font-size: .75rem;
        line-height: 1rem;
    }
}
.bg-debug-red {
  background-color: rgba(255, 0, 0, 0.05);
}

.bg-debug-green {
  background-color: rgba(0, 255, 0, 0.05);
}

.debugImg {
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  opacity: 0.2;
}

.debugPanel {
  opacity: 0.5;
}

.text-color-1 {
  color: #033878 !important;
}

.text-color-2 {
  color: #6DC7DD !important;
}

.text-color-3 {
  color: #0071BA !important;
}

.text-color-4 {
  color: #CEEAF3 !important;
}

.text-color-5 {
  color: #EAF6FB !important;
}

.text-color-6 {
  color: #00B3BB !important;
}

.text-color-7 {
  color: #493C90 !important;
}

.text-color-8 {
  color: #96368B !important;
}

.text-color-9 {
  color: #EA5284 !important;
}

.text-color-10 {
  color: #E73458 !important;
}

.text-color-11 {
  color: #F39325 !important;
}

.text-color-12 {
  color: #FDC41F !important;
}

.text-color-13 {
  color: #95C11F !important;
}

.text-color-14 {
  color: #6ADBA6 !important;
}

.text-color-15 {
  color: #FF0000 !important;
}

.text-color-16 {
  color: #0950CB !important;
}

.text-color-17 {
  color: #FDE71F !important;
}

.text-color-18 {
  color: #C7C7C7 !important;
}

.text-color-19 {
  color: #707070 !important;
}

.text-color-20 {
  color: #F3F3F3 !important;
}

.text-color-21 {
  color: #CCCCCC !important;
}

.bg-color-1 {
  background-color: #033878 !important;
}

.bg-color-2 {
  background-color: #6DC7DD !important;
}

.bg-color-3 {
  background-color: #0071BA !important;
}

.bg-color-4 {
  background-color: #CEEAF3 !important;
}

.bg-color-5 {
  background-color: #EAF6FB !important;
}

.bg-color-6 {
  background-color: #00B3BB !important;
}

.bg-color-7 {
  background-color: #493C90 !important;
}

.bg-color-8 {
  background-color: #96368B !important;
}

.bg-color-9 {
  background-color: #EA5284 !important;
}

.bg-color-10 {
  background-color: #E73458 !important;
}

.bg-color-11 {
  background-color: #F39325 !important;
}

.bg-color-12 {
  background-color: #FDC41F !important;
}

.bg-color-13 {
  background-color: #95C11F !important;
}

.bg-color-14 {
  background-color: #6ADBA6 !important;
}

.bg-color-15 {
  background-color: #FF0000 !important;
}

.bg-color-16 {
  background-color: #0950CB !important;
}

.bg-color-17 {
  background-color: #FDE71F !important;
}

.bg-color-18 {
  background-color: #C7C7C7 !important;
}

.bg-color-19 {
  background-color: #707070 !important;
}

.bg-color-20 {
  background-color: #F3F3F3 !important;
}

.bg-color-21 {
  background-color: #CCCCCC !important;
}

.bg-color-80-1 {
  background-color: rgba(3, 56, 120, 0.8) !important;
}

.bg-id-0 {
  background-color: #033878 !important;
}

.bg-id-1 {
  background-color: #493C90 !important;
}

.bg-id-2 {
  background-color: #96368B !important;
}

.bg-id-3 {
  background-color: #E73458 !important;
}

.bg-id-4 {
  background-color: #EA5284 !important;
}

.bg-id-5 {
  background-color: #95C11F !important;
}

.bg-id-6 {
  background-color: #6ADBA6 !important;
}

.bg-id-7 {
  background-color: #033878 !important;
}

.bg-id-8 {
  background-color: #493C90 !important;
}

.bg-id-9 {
  background-color: #96368B !important;
}

.bg-id-10 {
  background-color: #E73458 !important;
}

.bg-id-11 {
  background-color: #EA5284 !important;
}

.bg-id-12 {
  background-color: #95C11F !important;
}

.bg-id-13 {
  background-color: #6ADBA6 !important;
}

.text-id-0 {
  color: #033878 !important;
}

.text-id-1 {
  color: #493C90 !important;
}

.text-id-2 {
  color: #96368B !important;
}

.text-id-3 {
  color: #E73458 !important;
}

.text-id-4 {
  color: #EA5284 !important;
}

.text-id-5 {
  color: #95C11F !important;
}

.text-id-6 {
  color: #6ADBA6 !important;
}

.text-id-7 {
  color: #033878 !important;
}

.text-id-8 {
  color: #493C90 !important;
}

.text-id-9 {
  color: #96368B !important;
}

.text-id-10 {
  color: #E73458 !important;
}

.text-id-11 {
  color: #EA5284 !important;
}

.text-id-12 {
  color: #95C11F !important;
}

.text-id-13 {
  color: #6ADBA6 !important;
}

.color-error {
  color: red;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../../assets/fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Black.woff") format("woff"), url("../../assets/fonts/Roboto-Black.woff2") format("woff2"), url("../../assets/fonts/Roboto-Black.ttf") format("truetype"), url("../../assets/fonts/Roboto-Black.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("../../assets/fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-BlackItalic.woff") format("woff"), url("../../assets/fonts/Roboto-BlackItalic.woff2") format("woff2"), url("../../assets/fonts/Roboto-BlackItalic.ttf") format("truetype"), url("../../assets/fonts/Roboto-BlackItalic.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../../assets/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Bold.woff") format("woff"), url("../../assets/fonts/Roboto-Bold.woff2") format("woff2"), url("../../assets/fonts/Roboto-Bold.ttf") format("truetype"), url("../../assets/fonts/Roboto-Bold.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../../assets/fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-BoldItalic.woff") format("woff"), url("../../assets/fonts/Roboto-BoldItalic.woff2") format("woff2"), url("../../assets/fonts/Roboto-BoldItalic.ttf") format("truetype"), url("../../assets/fonts/Roboto-BoldItalic.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("../../assets/fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Italic.woff") format("woff"), url("../../assets/fonts/Roboto-Italic.woff2") format("woff2"), url("../../assets/fonts/Roboto-Italic.ttf") format("truetype"), url("../../assets/fonts/Roboto-Italic.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("../../assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Light.woff") format("woff"), url("../../assets/fonts/Roboto-Light.woff2") format("woff2"), url("../../assets/fonts/Roboto-Light.ttf") format("truetype"), url("../../assets/fonts/Roboto-Light.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: url("../../assets/fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-LightItalic.woff") format("woff"), url("../../assets/fonts/Roboto-LightItalic.woff2") format("woff2"), url("../../assets/fonts/Roboto-LightItalic.ttf") format("truetype"), url("../../assets/fonts/Roboto-LightItalic.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../../assets/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Medium.woff") format("woff"), url("../../assets/fonts/Roboto-Medium.woff2") format("woff2"), url("../../assets/fonts/Roboto-Medium.ttf") format("truetype"), url("../../assets/fonts/Roboto-Medium.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("../../assets/fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-MediumItalic.woff") format("woff"), url("../../assets/fonts/Roboto-MediumItalic.woff2") format("woff2"), url("../../assets/fonts/Roboto-MediumItalic.ttf") format("truetype"), url("../../assets/fonts/Roboto-MediumItalic.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("../../assets/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Regular.woff") format("woff"), url("../../assets/fonts/Roboto-Regular.woff2") format("woff2"), url("../../assets/fonts/Roboto-Regular.ttf") format("truetype"), url("../../assets/fonts/Roboto-Regular.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("../../assets/fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-Thin.woff") format("woff"), url("../../assets/fonts/Roboto-Thin.woff2") format("woff2"), url("../../assets/fonts/Roboto-Thin.ttf") format("truetype"), url("../../assets/fonts/Roboto-Thin.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("../../assets/fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/Roboto-ThinItalic.woff") format("woff"), url("../../assets/fonts/Roboto-ThinItalic.woff2") format("woff2"), url("../../assets/fonts/Roboto-ThinItalic.ttf") format("truetype"), url("../../assets/fonts/Roboto-ThinItalic.svg#svgFontName") format("svg");
}
.typo-header {
  font-family: "Roboto-Bold";
  font-size: 1.7rem;
  line-height: 1.7rem;
  color: white;
}

.typo-chapter-title {
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 2.2rem;
  line-height: 2.2rem;
  color: white;
}

.typo-header-chapter {
  font-family: "Roboto";
  font-size: 2.75rem;
  line-height: 2.75rem;
  color: #033878;
}

.typo-chapter-content {
  font-family: "Roboto-Medium";
  font-size: 1rem;
  color: #033878;
}

.typo-chapter-number {
  font-family: "Roboto-Bold";
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: rgba(255, 255, 255, 0.5);
}

.typo-subchapter-number {
  font-family: "Roboto-Bold";
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #033878;
}

.typo-title {
  font-family: "Roboto-Regular";
  font-size: 1.75em;
  line-height: 1.75rem;
  color: white;
}

.typo-template-title {
  font-family: "Roboto-Bold";
  font-size: 2.75em;
  line-height: 2.75rem;
  color: #033878;
}

.typo-template-subtitle {
  font-family: "Roboto-Bold";
  font-size: 1rem;
  line-height: 1rem;
  color: #033878;
}

.typo-description {
  font-family: "Roboto-Regular";
  font-size: 1rem;
  line-height: 1.2rem;
  color: #707070;
}

.typo-modal-header {
  font-family: "Roboto-Regular";
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #033878;
}

.typo-error {
  text-transform: uppercase;
  font-family: "Roboto-MediumItalic";
  font-size: 0.8rem;
  color: red;
}

@media (max-width: 1200px) {
  .typo-chapter-title {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  .typo-header-chapter {
    font-family: "Roboto";
    font-size: 2.25rem;
    line-height: 2.25rem;
    color: #033878;
  }

  .typo-title {
    font-size: 1.5em;
    line-height: 1.5rem;
  }

  .typo-description {
    font-size: 0.85rem;
    line-height: 1.1rem;
  }
}
@media (max-width: 768px) {
  .typo-chapter-title {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .typo-header-chapter {
    font-family: "Roboto";
    font-size: 2.25rem;
    line-height: 2.25rem;
    color: #033878;
  }
}
@media (max-width: 576px) {
  .typo-header {
    font-size: 0.8rem;
    line-height: 0rem;
  }

  .typo-chapter-title {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .typo-template-title {
    font-size: 1.75em;
    line-height: 1.75rem;
  }

  .typo-header-chapter {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  .typo-title {
    font-size: 1.25em;
    line-height: 1.25rem;
  }

  .typo-description {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
.modal {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal.active {
  display: flex;
}

.mod-header {
  height: 3.5rem;
}

.mod-body {
  max-height: 80vh;
}

.mod-dialog {
  width: 80vw;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
}

.mod-close {
  width: 1.5rem;
  height: auto;
}

.mod-add {
  width: 2rem;
  height: auto;
}

@media (max-width: 576px) {
  .mod-dialog {
    width: 100vw;
  }
}
body {
  font-family: "Roboto-Regular" !important;
  font-size: 4rem;
  background-color: #F3F3F3 !important;
  height: 100vh;
}

body.modal-open {
  overflow: hidden;
}

button {
  background-color: #6DC7DD;
  border: 2px solid white;
  border-radius: 0.5rem !important;
  color: white;
  padding: 0.5rem 3.5rem;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox] + label {
  background: url("../pictos/CheckboxEmpty.svg") no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  padding-left: 2rem;
  width: auto;
}

input[type=checkbox]:checked + label {
  background: url("../pictos/CheckboxCheck.svg") no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  padding-left: 2rem;
  width: auto;
}

.logo {
  width: 4rem;
  height: 2rem;
}

.wm-page {
  max-width: 1350px;
}

.h-page {
  height: calc(100% - 4rem);
}

.overflow-y {
  overflow-y: auto;
}

.icon-standard {
  width: auto;
  height: 2rem;
}

.icon-small {
  width: auto;
  height: 1rem;
}

.icon-medium {
  width: auto;
  height: 1.5rem;
}

.block-description {
  max-height: 32vh;
  overflow-y: scroll;
  padding-right: 0.5rem;
  white-space: pre-line;
}

.arrow {
  width: 1.5rem;
  height: auto;
}

.warningSvg {
  width: 1.5rem;
  height: 1.5rem;
}

.pre-line {
  white-space: pre-line;
}

.bg-img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.imgfitparent {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 70vh;
}

.icon-standard {
  width: auto;
  height: 2rem;
}

@media (max-width: 768px) {
  .icon-standard {
    width: auto;
    height: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .h-page {
    height: calc(100% - 4rem - 20px);
  }
}
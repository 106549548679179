
$color-1: #033878;
$color-2: #6DC7DD;
$color-3: #0071BA;
$color-4: #CEEAF3;
$color-5: #EAF6FB;
$color-6: #00B3BB;
$color-7: #493C90;
$color-8: #96368B;
$color-9: #EA5284;
$color-10: #E73458;
$color-11: #F39325;
$color-12: #FDC41F;
$color-13: #95C11F;
$color-14: #6ADBA6;
$color-15: #FF0000;
$color-16: #0950CB;
$color-17: #FDE71F;
$color-18: #C7C7C7;
$color-19: #707070;
$color-20: #F3F3F3;
$color-21: #CCCCCC;

// Couleur Texte
.text-color-1   {   color: $color-1  !important;    }
.text-color-2   {   color: $color-2  !important;    }
.text-color-3   {   color: $color-3  !important;    }
.text-color-4   {   color: $color-4  !important;    }
.text-color-5   {   color: $color-5  !important;    }
.text-color-6   {   color: $color-6  !important;    }
.text-color-7   {   color: $color-7  !important;    }
.text-color-8   {   color: $color-8  !important;    }
.text-color-9   {   color: $color-9  !important;    }
.text-color-10  {   color: $color-10 !important;    }
.text-color-11  {   color: $color-11 !important;    }
.text-color-12  {   color: $color-12 !important;    }
.text-color-13  {   color: $color-13 !important;    }
.text-color-14  {   color: $color-14 !important;    }
.text-color-15  {   color: $color-15 !important;    }
.text-color-16  {   color: $color-16 !important;    }
.text-color-17  {   color: $color-17 !important;    }
.text-color-18  {   color: $color-18 !important;    }
.text-color-19  {   color: $color-19 !important;    }
.text-color-20  {   color: $color-20 !important;    }
.text-color-21  {   color: $color-21 !important;    }

// Couleur de fond color
.bg-color-1     {   background-color: $color-1  !important;       }
.bg-color-2     {   background-color: $color-2  !important;       }
.bg-color-3     {   background-color: $color-3  !important;       }
.bg-color-4     {   background-color: $color-4  !important;       }
.bg-color-5     {   background-color: $color-5  !important;       }
.bg-color-6     {   background-color: $color-6  !important;       }
.bg-color-7     {   background-color: $color-7  !important;       }
.bg-color-8     {   background-color: $color-8  !important;       }
.bg-color-9     {   background-color: $color-9  !important;       }
.bg-color-10    {   background-color: $color-10 !important;       }
.bg-color-11    {   background-color: $color-11 !important;       }
.bg-color-12    {   background-color: $color-12 !important;       }
.bg-color-13    {   background-color: $color-13 !important;       }
.bg-color-14    {   background-color: $color-14 !important;       }
.bg-color-15    {   background-color: $color-15 !important;       }
.bg-color-16    {   background-color: $color-16 !important;       }
.bg-color-17    {   background-color: $color-17 !important;       }
.bg-color-18    {   background-color: $color-18 !important;       }
.bg-color-19    {   background-color: $color-19 !important;       }
.bg-color-20    {   background-color: $color-20 !important;       }
.bg-color-21    {   background-color: $color-21 !important;       }

// Couleur de fond à 80%
.bg-color-80-1     {   background-color: rgba($color: $color-1, $alpha: 0.8) !important;       }

// Couleur de fond par id
.bg-id-0   {   background-color: $color-1 !important;     }
.bg-id-1   {   background-color: $color-7 !important;     }
.bg-id-2   {   background-color: $color-8 !important;     }
.bg-id-3   {   background-color: $color-10 !important;    }
.bg-id-4   {   background-color: $color-9 !important;     }
.bg-id-5   {   background-color: $color-13 !important;    }
.bg-id-6   {   background-color: $color-14 !important;    }
.bg-id-7   {   background-color: $color-1 !important;     }
.bg-id-8   {   background-color: $color-7 !important;     }
.bg-id-9   {   background-color: $color-8 !important;     }
.bg-id-10  {   background-color: $color-10 !important;    }
.bg-id-11  {   background-color: $color-9 !important;     }
.bg-id-12  {   background-color: $color-13 !important;    }
.bg-id-13  {   background-color: $color-14 !important;    }

// Couleur de texte par id
.text-id-0   {   color: $color-1 !important;     }
.text-id-1   {   color: $color-7 !important;     }
.text-id-2   {   color: $color-8 !important;     }
.text-id-3   {   color: $color-10 !important;    }
.text-id-4   {   color: $color-9 !important;     }
.text-id-5   {   color: $color-13 !important;    }
.text-id-6   {   color: $color-14 !important;    }
.text-id-7   {   color: $color-1 !important;     }
.text-id-8   {   color: $color-7 !important;     }
.text-id-9   {   color: $color-8 !important;     }
.text-id-10  {   color: $color-10 !important;    }
.text-id-11  {   color: $color-9 !important;     }
.text-id-12  {   color: $color-13 !important;    }
.text-id-13  {   color: $color-14 !important;    }

// Autres
.color-error {  color: red; }